export function unhideHtmlElement(element) {
	if (element.classList.contains("hidden-element")) {
		element.classList.remove("hidden-element");
	}
}

export function hideHtmlElement(element) {
	if (!element.classList.contains("hidden-element")) {
		element.classList.add("hidden-element");
	}
}
