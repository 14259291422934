import { hideHtmlElement, unhideHtmlElement } from "../../utilities/misc.js";

function validatePasswordResetForm() {
	let required = [
		{
			name: "txtPassword",
			element: document.getElementById("txtPassword"),
			value: document.getElementById("txtPassword").value,
			text: "Password is required.",
			required: true,
		},
		{
			name: "passwordLength",
			element: document.getElementById("txtPassword"),
			value: document.getElementById("txtPassword").value,
			text: "Password must be at least 6 characters.",
			required: true,
		},
		{
			name: "txtConfirmPasword",
			element: document.getElementById("txtConfirmPasword"),
			value: document.getElementById("txtConfirmPasword").value,
			text: "Password must match to confirm.",
			required: true,
		},
	];
	let liList = "";

	required.forEach((item) => {
		if (item.value.trim() === "") {
			item.element.classList.add("text-danger", "border-danger");
			liList += `<li>${item.text}</li>`;
		}
		if (item.name === "txtConfirmPasword") {
			let pwValue = document.getElementById("txtPassword").value;
			if (pwValue !== item.value) {
				item.element.classList.add("text-danger", "border-danger");
				liList += `<li>${item.text}</li>`;
			}
		}
	});
	let oldPassword = document.getElementById("txtOldPassword");
	if (
		oldPassword &&
		(oldPassword.classList.contains("bg-danger") || oldPassword.value.length === 0)
	) {
		liList += `<li>A valid old password must be entered.</li>`;
	}

	let ulList = `<ul class='text-light'>${liList}</ul>`;
	if (liList.length > 0) {
		messageBanner("messageBanner", ulList, 10000, "bg-danger");
		return false;
	} else return true;
}

function saveNewPassword(userElement) {
	if (userElement) {
		var mode = userElement.dataset["reset"];
	}
	let pwElement = document.getElementById("txtPassword");
	let idElement = document.getElementById("hfSelectedUserId");
	let options = {
		pw: pwElement.value,
		userId: idElement.value,
	};
	postData("/api/UserAccount/newPassword", JSON.stringify(options)).then((data) => {
		if (data) {
			if (data.results > 0) {
				if (mode === "reset") {
					hideHtmlElement(document.getElementById("bttnSave"));
					messageBanner(
						"messageBanner",
						"New password was successfully saved.",
						10000,
						"bg-success"
					);
					unhideHtmlElement(document.getElementById("linkToPortal"));
				} else if (mode === "new") {
					FadeNewPwWindow();
				}
			}
		}
		return null;
	});
}

function verifyOldPassword() {
	let userid = document.getElementById("hfSelectedUserId").value;
	let oldPw = document.getElementById("txtOldPassword");
	if (oldPw && oldPw.value.length > 0) {
		let options = {
			userId: userid,
			password: oldPw.value,
		};
		postData("/api/UserAccount/oldPassword", JSON.stringify(options)).then((data) => {
			if (data) {
				if (data.exists === false) {
					oldPw.classList.add("bg-danger", "text-light");
					messageBanner(
						"messageBanner",
						"The password entered in the Old Password box below could not be found.",
						10000,
						"bg-danger"
					);
				} else {
					oldPw.classList.remove("bg-danger", "text-light");
				}
			}
		});
	}
}

export { saveNewPassword, validatePasswordResetForm, verifyOldPassword };
