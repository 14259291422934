import {
	saveNewPassword,
	validatePasswordResetForm,
	verifyOldPassword,
} from "./modules/userAccounts/userPassword.js";
import { validateRequiredInputs } from "./utilities/formValidation.js";
import { messageBanner, hideBanner } from "./utilities/msgBanner.js";
import { populateSelectOptions } from "./utilities/selectUtils.js";
import { hideHtmlElement, unhideHtmlElement } from "./utilities/misc.js";
import {
	isTokenExpired,
	parseJwt,
	GetPaymentToken,
	RenewToken,
	RevokeToken,
} from "./utilities/webToken.js";

globalThis.verifyCredentials = verifyCredentials;
globalThis.hideBanner = hideBanner;
globalThis.messageBanner = messageBanner;
globalThis.onAssociatedDealerSelect = onAssociatedDealerSelect;
globalThis.saveTokenLocal = saveTokenLocal;
globalThis.onResetSubmitClick = onResetSubmitClick;
globalThis.verifyOldPassword = verifyOldPassword;

$(function () {
	if ($("#DealerSelectionBlock").css("display") === "none") {
		RevokeToken();
		let inputs = document.querySelectorAll("input");
		inputs.forEach((item) => {
			addEventListener("keyup", function () {
				let valid = true;
				let submit = document.querySelector("#btnSubmit");
				if (item.type === "password") {
					valid = validateRequiredInputs(item, 6);
					if (valid === true) {
						submit.disabled = !valid;
					}
				} else {
					valid = validateRequiredInputs(item);
				}
			});
		});
		$("#txtUsername").focus();
	} else {
		$("#UserDealerList").focus();
	}

	if (document.getElementById("Login_page")) {
		checkResetOnLogin();
	}

	if (document.getElementById("VerifyEmail_page")) {
		localStorage.removeItem("reset_token");
	}

	$("input.password").keypress(function () {
		validateRequiredInputs(this, 6);
	});

	$("input.password").focusout(function () {
		validateRequiredInputs(this);
	});

	$("input.password[type='text']").on("paste", function () {
		validateRequiredInputs(this);
	});

	$("#RecoveryUsername").keypress(function (event) {
		if (event.keyCode === 13) {
			onPasswordRecoveryClick();
		}
	});

	let reset = document.getElementById("hfresettoken");
	if (reset && reset.value.length > 1) saveTokenLocal(reset.value);
});

function checkResetOnLogin() {
	let reset_token = localStorage.getItem("reset_token");
	if (reset_token) {
		let tkn = parseJwt(reset_token);
		let expired = new Date(tkn.exp * 1000) < new Date();
		if (expired) {
			let msg = `The reset link has expired.<br/> Click on <strong>Forgot your password</strong> 
			link below and follow the instructions for resetting your password.`;
			messageBanner("messageBanner", msg, 15000, "bg-warning text-dark");
			localStorage.removeItem("reset_token");
		}
	}
}

function onResetSubmitClick(element) {
	if (validatePasswordResetForm()) {
		saveNewPassword(element);
	}
}

function saveTokenLocal(token) {
	localStorage.setItem("reset_token", token);
	validateResetToken(token);
}

function validateResetToken(token) {
	let tkn = parseJwt(token);
	let expired = new Date(tkn.exp * 1000) < new Date();

	if (expired) {
		window.location.href = "/cpages/userlogin.aspx";
		let msg = `Sorry. 
		This link has expired.\n
		Use the <strong>Forgot you password?</strong> link at login.`;
		messageBanner("messageBanner", msg, 20000, "bg-danger");
		console.log("This is expired");
	}
}

function getCookie(cName, cKey) {
	const name = cName + "=";
	const cDecoded = decodeURIComponent(document.cookie); //to be careful
	const cArr = cDecoded.split("; ");
	let res;
	cArr.forEach((val) => {
		if (val.indexOf(name) === 0) {
			res = val.substring(val.indexOf(cKey), val.indexOf("&"));
		}
	});
	return res;
}

function verifyLoginForm() {
	let elements = document.querySelectorAll(".required");
	elements.forEach((item) => {
		validateRequiredInputs(item);
	});
}

function verifyCredentials() {
	// event.preventDefault();
	document.getElementById("btnSubmit").setAttribute("disabled", "disabled");
	var username = document.getElementById("txtUsername");
	var pword = document.getElementById("txtPassword");

	let options = {
		username: username.value,
		password: pword.value,
	};
	if (options.username && options.password) {
		postData("/api/UserAccount/verifyLogin", JSON.stringify(options)).then((data) => {
			if (data.status === "ERROR") {
				messageBanner("messageBanner", data.msg, 5000, "bg-danger");
				username.classList.add("text-danger", "border-danger");
				pword.classList.add("text-danger", "border-danger");
				return false;
			} else {
				if (data && data.IsSignedIn === true) {
					let content = `UserName=${data.Username};UserID=${data.Id};`;
					setCookie("UserInfo", content);
				}
				document.getElementById("hfUserId").value = data.Id;
				document.getElementById("hfPersonalname").value = data.Username;
				GetPaymentToken(data.Username);
				SelectAssociatedDealers(data.Id);
			}
		});
	} else {
		validateRequiredInputs(username);
		validateRequiredInputs(pword);
		messageBanner(
			"messageBanner",
			"Both username and password are required.",
			5000,
			"bg-danger"
		);
	}
}

function SelectAssociatedDealers(userId) {
	let options = {
		userId: userId,
	};
	postData("/api/useraccount/associatedDealerSelect", JSON.stringify(options))
		.then((resp) => {
			let data = resp;
			if (data) {
				if (data.dealerOptions.length < 1) {
					messageBanner(
						"messageBanner",
						"<div>The username entered has no active business partner associations.</div><div>Please contact customer service if you desire assistance.</div>",
						3000,
						"bg-danger"
					);
					showModal("#AlertWindow");
				} else {
					let selectBox = document.getElementById("AssociatedDealers");
					populateSelectOptions(selectBox, data.dealerOptions, "", false);
					if (data.dealerOptions.length === 1) {
						onAssociatedDealerSelect();
					}
					selectBox.addEventListener("keypress", function (e) {
						if (e.key === "Enter" || e.key === 13) {
							e.preventDefault();
							onAssociatedDealerSelect();
						}
					});
					return selectBox;
				}
				return null;
			} else return null;
		})
		.then((selectBox) => {
			if (selectBox !== null && selectBox.options.length > 1) {
				showModal("#DealerSelectionBlock");
				selectBox.focus();
			}
		});
}

globalThis.onPasswordRecoveryClick = function () {
	let name = document.getElementById("RecoveryUsername").value;
	let options = {
		name: name,
	};
	postData("/api/UserAccount/checkUserEmailVerified", JSON.stringify(options)).then((resp) => {
		let data = resp;
		if (data) {
			if (data.userEmailVerified && data.emailSent) {
				hideHtmlElement(document.getElementById("SignInBlock"));
				let success = document.getElementById("successMsg");
				success.innerHTML = data.userMsg;
				unhideHtmlElement(document.getElementById("SuccessMessageBlock"));
			} else {
				if (data.userMsg && data.userMsg.length > 0) {
					messageBanner("messageBanner", data.userMsg, 15000, "bg-danger");
				} else {
					messageBanner("messageBanner", data.Message, 15000, "bg-danger");
				}
			}
		}
	});
};

function onAssociatedDealerSelect() {
	let sbDealer = document.getElementById("AssociatedDealers");
	document.getElementById("hfDealerId").value = sbDealer.value;
	__doPostBack("AssociateDealer", true);
}

function setCookie(cname, cvalue) {
	const d = new Date();
	d.setTime(d.getTime() + 8 * 60 * 60 * 1000);
	document.cookie = `${cname}=${cvalue};expires=${d.toUTCString()};path=/`;
}
