import { hideHtmlElement, unhideHtmlElement } from "./misc.js";

function hideBanner(elementId) {
	let element = document.getElementById(elementId);
	element.innerHTML = "";
}

function applyTemplate(elementId, msg, bgcolor) {
	return `
  <div class="col-12 ${bgcolor} text-light position-absolute">
    <span id='close' class="close text-light" onclick='hideBanner("${elementId}");'></span>
    <div class="p-3">${msg}</div>
    </div>
    `;
}

const messageBanner = (elementId, msg, timeout = 13000, bgcolor = "bg-warning") => {
	let element = document.getElementById(elementId);
	unhideHtmlElement(element);
	element.innerHTML = applyTemplate(elementId, msg, bgcolor);
	setTimeout(function () {
		element.innerHTML = ``;
		hideHtmlElement(element);
	}, timeout);
};

export { messageBanner, hideBanner };
