import { messageBanner } from "./msgBanner.js";

function validateRequiredInputs(element, minChar = 3) {
	let retval = true;
	if (element.classList.contains("email")) {
		if (validateEmailAddress(element.value) === true) {
			element.classList.remove("text-danger", "border-danger");
		} else {
			element.classList.add("text-danger", "border-danger");
			retval = false;
		}
	} else {
		if (element.value.length < minChar - 1) {
			element.classList.add("text-danger", "border-danger");
			retval = false;
		} else {
			element.classList.remove("text-danger", "border-danger");
		}
	}
	return retval;
}

export { validateRequiredInputs };
