const populateSelectOptions = function (
	sb,
	list,
	notselectedVal = "-2",
	addNS = true,
	dvalue = null
) {
	if (sb) {
		RemoveAllSelectOptions(sb);
		if (addNS === true) {
			let opt0 = document.createElement("option");
			if (list.length === 0) {
				opt0.text = "Please wait...";
				opt0.value = "0";
			} else {
				opt0.text = "Not Selected";
				opt0.value = notselectedVal;
			}
			sb.appendChild(opt0);
		}
		list.forEach((item) => {
			let opt = document.createElement("option");
			opt.text = item.Name;
			opt.value = item.Value;
			if (dvalue && dvalue.length > 0) {
				opt.dataset[dvalue] = item.categoryId;
				opt.dataset["other"] = item.finishOther;
			}
			if (item.Selected || list.length === 1) {
				opt.selected = true;
			}
			if (list.length > 1) {
				sb.removeAttribute("disabled");
			}
			sb.appendChild(opt);
		});
	}
	return sb;
};

function RemoveAllSelectOptions(selectDrop) {
	while (selectDrop.options && selectDrop.options.length > 0) {
		selectDrop.remove(0);
	}
}

function resetSelectElement(selectDrop) {
	let opt0 = document.createElement("option");
	opt0.text = "Please wait...";
	opt0.value = "0";
	RemoveAllSelectOptions(selectDrop);
	selectDrop.appendChild(opt0);
}
export { resetSelectElement, populateSelectOptions };
