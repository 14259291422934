function isTokenExpired(token) {
	const expiry = JSON.parse(atob(token.split(".")[1])).exp;
	return Math.floor(new Date().getTime() / 1000) >= expiry;
}

function parseJwt(token) {
	var base64Url = token.split(".")[1];
	var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
	var jsonPayload = decodeURIComponent(
		window
			.atob(base64)
			.split("")
			.map(function (c) {
				return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join("")
	);

	return JSON.parse(jsonPayload);
}

async function GetPaymentToken(username) {
	let exists = localStorage.getItem("token");
	if (exists == null) {
		let user = {
			status: username,
		};
		await FetchToken("/api/pay/token", JSON.stringify(user))
			.then(function (token) {
				//save the token in local storage
				localStorage.setItem("token", token);
			})
			.catch((error) => {
				alert("Failed to get token:" + error);
			});
	}
}

async function FetchToken(url = "", data = {}) {
	const response = await fetch(url, {
		method: "POST",
		mode: "cors", // no-cors, *cors, same-origin
		cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
		credentials: "same-origin", // include, *same-origin, omit
		headers: {
			"Content-Type": "application/json",
			// 'Content-Type': 'application/x-www-form-urlencoded',
		},
		redirect: "follow", // manual, *follow, error
		referrerPolicy: "no-referrer",
		body: JSON.stringify(data),
	}).catch((error) => alert(error));
	return response.json();
}

function RenewToken(args) {
	let options = {
		status: args.Username,
	};
	FetchToken("api/pay/token", JSON.stringify(options)).then((data) => {
		if (data) {
			localStorage.setItem("token", data);
			return 1;
		} else {
			ShowExceptionAlert("Failed to get new token.");
		}
	});
}

function RevokeToken() {
	let tkn = localStorage.getItem("token");
	if (tkn) {
		localStorage.removeItem("token");
	}
}

export { isTokenExpired, parseJwt, GetPaymentToken, RenewToken, RevokeToken };
